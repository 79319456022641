<script setup lang="ts">
import type { LigatureT } from 'currenda-design-system/dist/types/global/props/icon-props.types';

import type { IComplaint } from '@Models/Complaint/complaint.model';
import type { IItem } from '@Models/Item/item.model';
import complaintsStatus from '@Enums/complaintsStatus';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useDate } from '@Composables/date';
import { useComplaints } from '@Composables/complaints';
import { useItemStore } from '@Store/item.module';

import AvatarComponent from '@Components/AvatarComponent.vue';
import PutInOfficeChips from '@Components/complaint/PutInOfficeChips.vue';
import TextBody2 from '@Typography/TextBody2.vue';
import TextBody1 from '@Typography/TextBody1.vue';
import TextCaption from '@Typography/TextCaption.vue';

const props = defineProps({
  complaint: {
    required: true,
    type: Object as () => IComplaint
  }
});
const router = useRouter();
const itemStore = useItemStore();
const { setComplaintTitle, setComplaintStatus } = useComplaints();
const { $routeNames } = useNuxtApp();
const { formatDate } = useDate();

const item = computed<IItem>(() => {
  return itemStore.getItem;
});

const setUserName = computed(() => {
  const fullName: string[] = [];
  if (props.complaint.userData.firstName) fullName.push(`${props.complaint.userData.firstName} `);
  if (props.complaint.userData?.lastName) fullName.push(`${props.complaint.userData.lastName} `);

  return fullName.join(' ');
});

function setIcon() {
  const icon: Record<string, LigatureT> = {
    [complaintsStatus.UNRECOGNIZED]: 'exclamation_mark',
    [complaintsStatus.SUBMITTED_TO_COURT]: 'history',
    [complaintsStatus.ACCEPTED_BY_BAILIFF]: 'checkmark',
    [complaintsStatus.ACCEPTED_BY_JUDGE]: 'checkmark',
    [complaintsStatus.REJECTED_BY_JUDGE]: 'close',
    [complaintsStatus.DISMISSED_BY_JUDGE]: 'close'
  };
  return icon[props.complaint.complaintStatus];
}

async function showComplaint() {
  if (item.value.bailiff) {
    await router.push({
      name: $routeNames.bailiff.itemComplaint(item.value.id, props.complaint.id),
      params: { itemId: item.value.id, complaintId: props.complaint.id }
    });
  } else {
    await router.push({
      name: $routeNames.user.itemComplaint(props.complaint.itemId, props.complaint.id),
      params: {
        itemId: props.complaint.itemId,
        complaintId: props.complaint.id
      }
    });
  }
}
</script>

<template>
  <div
    @click="showComplaint()"
    class="element d-flex flex-direction-row mb-xsxl cursor--pointer"
  >
    <div
      class="details__box--width"
      v-if="!item.bailiff"
    >
      <div class="logo__icon vertical-align-center label--info">
        <img src="@/assets/images/svg/flag-icon.svg" />
      </div>
    </div>
    <div
      class="details__box--width"
      v-else
    >
      <AvatarComponent :text="setUserName" />
    </div>
    <div class="w-100 p-relative">
      <TextBody2
        v-if="item.bailiff"
        :dark="true"
        class="font-weight-bold"
      >
        <PutInOfficeChips v-if="!complaint.userData.userId" />
        {{ complaint.userData.firstName }}
        {{ complaint.userData.lastName }}
      </TextBody2>
      <TextBody1 class="mb-sm d-flex justify-space-between">
        {{ setComplaintTitle(complaint.complaintType) }}
      </TextBody1>
      <TextCaption
        class="d-flex justify-space-between"
        :mediumDark="true"
        ><span
          >{{ $t('item.id') }} {{ complaint.itemId }}<span class="px-xs">&bull;</span> {{ complaint.itemName }}</span
        >
        <span>{{ formatDate(complaint.dateCreated) }}</span>
      </TextCaption>
      <CdsChip
        variant="solid"
        type="default"
        size="small"
        class="chip--position"
        :prepend-icon="setIcon()"
        :class="[
          {
            'label--warning':
              complaint.complaintStatus == complaintsStatus.UNRECOGNIZED ||
              complaint.complaintStatus == complaintsStatus.SUBMITTED_TO_COURT
          },
          {
            'label--success':
              complaint.complaintStatus == complaintsStatus.ACCEPTED_BY_BAILIFF ||
              complaint.complaintStatus == complaintsStatus.ACCEPTED_BY_JUDGE
          },
          {
            'label--secondary':
              complaint.complaintStatus == complaintsStatus.REJECTED_BY_JUDGE ||
              complaint.complaintStatus == complaintsStatus.DISMISSED_BY_JUDGE
          }
        ]"
      >
        <TextCaption
          class="hidden-sm-and-down"
          :mediumDark="true"
          >{{ setComplaintStatus(complaint.complaintStatus) }}</TextCaption
        >
      </CdsChip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@Assets/scss/components/item-details/notes/single-note.scss';
@import '@Assets/scss/avatar-component.scss';

.chip--position {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
