<script setup lang="ts"></script>

<template>
  <cds-skeleton-loader
    class="mt-md"
    type="list-item-avatar-three-line"
    v-for="n in 6"
    :key="n"
  ></cds-skeleton-loader>
</template>
