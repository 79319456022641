<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { fetchComplaintsService } from '@Services/complaint.service';
import type { IComplaint } from '@Models/Complaint/complaint.model';
import SingleComplaint from '@Components/items-details/complaints/SingleComplaint.vue';
import SkeletonLoaderForTabs from '@Components/items-details/SkeletonLoaderForTabs.vue';

const route = useRoute();
const loader = ref<boolean>(true);
const complaints = ref<IComplaint[]>([]);

onMounted(async () => {
  const response = await fetchComplaintsService(route.params.itemId as string);
  if (response) {
    complaints.value = response;
    loader.value = false;
  }
});
</script>

<template>
  <SkeletonLoaderForTabs v-if="loader" />
  <template v-else>
    <div v-if="complaints.length >= 1">
      <SingleComplaint
        v-for="(complaint, index) in complaints"
        :key="index"
        :complaint="complaint"
      />
    </div>
    <div v-else>
      <div class="complaint-empty">
        <div class="complaint-empty__icon cursor--pointer">
          <CdsIcon icon="note" />
        </div>
        <div class="complaint-empty__header">
          {{ $t('bailiffItemDetails.complaints.onRefusalEmpty.header') }}
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
@import '@Assets/scss/components/complaint/complaints-empty.scss';
@import '@Assets/scss/components/bidder/bidder-list.scss';
</style>
